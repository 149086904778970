import React from 'react'
import "../Css/About_us.css"
import { ArrowRight } from '@mui/icons-material'
function About_us_page() {
  return (
    <div className='container-fluid p-0'>
        <div className='row'>
            <div className='col-sm-12 p-5 about_us_page_title_container'>
                <div className='d-flex'>
                    <h5>Home</h5><ArrowRight/><h5 className='text-secondary'>About Us</h5>
                </div>
                <div className='mt-3'>
                    <h2>About Us</h2>
                </div>
            </div>
        </div>
        <div className='container my-lg-5'>
           <div className='row my-5 py-sm-3 py-5'>
           <div className='col-sm-12 col-lg-6'>
                <img src="/Assets/about_us_1.png" alt='about-us image' className='img-fluid'/>
            </div>
            <div className='col-sm-12 col-lg-6'>
                <div className='aboutuspage_content_container'>
                    <h6> About us</h6>
                    <h3>To help people love how they live in moments that matter. Welcome to Buska Lights.</h3>
                    <p>Buska Lighting is Enlightened by Design. We’re excited by design, too. We travel the world to bring you everything from authentic and iconic pieces, to the latest in contemporary interior décor, all sourced from global design brands, including exclusive pieces you won’t find anywhere else. Our lifestyle brands offer inspired living through high-quality products, exclusive designs, andtimeless style - all powered by digital design and visualization tools that provide seamless shopping solutions in-store and online. With a distinct architectural aesthetic, experiential store environment, and online community, we engage with our customers through social media, mobile shopping, design services, gift registry, and more.</p>
                </div>
            </div>

           </div>
           <div className='row my-5 py-lg-5'>
           
            <div className='col-sm-12 col-lg-6'>
                <div className='aboutuspage_content_container'>
                    <h6> About us</h6>
                    <h3>We Promise</h3>
                    <p>
                    At Buska Lighting, our promise is to illuminate your world with excellence and innovation. We are committed to providing our customers with the highest quality lighting solutions that blend functionality with aesthetic appeal. Our dedication to sustainability and energy efficiency ensures that every product we offer not only enhances your space but also contributes to a greener future. 
                    </p>
                    <p>
                   <span className='fw-bolder'> Quality Craftsmanship:</span> Every Buska Lighting product is crafted with meticulous attention to detail. We use only the finest materials and the latest technology to ensure durability and superior performance. Our rigorous quality control processes guarantee that you receive nothing but the best.  
                   </p>
                   <p>
                   <span className='fw-bolder'> Innovative Designs:</span> We understand that lighting is not just about illumination; it’s about creating an atmosphere. Our team of designers continually explores new trends and technologies to bring you innovative lighting solutions that are as beautiful as they are functional. From contemporary to classic, our diverse range of designs caters to all styles and preferences.
                   </p>
                </div>
            </div>
            <div className='col-sm-12 col-lg-6'>
              <div className='d-flex align-center'>
              <img src="/Assets/we_Promise.jpg" alt='about-us image' className='img-fluid'/>
              </div>
            </div>

           </div>
        </div>


    </div>
  )
}

export default About_us_page