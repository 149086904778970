import React from 'react'
import { Link } from 'react-router-dom'
import "../Css/About_us.css"
function About_us() {
  return (
    <div className='container-fluid bg-light mt-5 p-0' id="aboutus">
       
       
       <div className='row'>
        <div className='col-sm-12 col-lg-6'>
            <img className='img-fluid' src="../Assets/blog/blog-4.jpg" alt=""/>
        </div>
        <div className='col-sm-12 col-lg-6'>
            <div className=' About_us_container'>
                <h5>About Us</h5>
                <p>Dekorfine is committed to your satisfaction and peace of mind with every purchase.<br/>Our team is dedicated to
                    making your shopping experience a delight.</p>
            </div>
            <div className='w-100 d-flex justify-content-end p-3'> 
                <Link to="/about_us"><button className='read_more_button '> Read more </button></Link>
            </div>
        </div>
       </div>
    
    </div>
  )
}

export default About_us