
 const collection=[
    {
        collection_name:"Chandelier",
        Description:"A chandelier is not merely a source of light; it is a statement of elegance and sophistication. Hanging gracefully from the ceiling, its myriad crystals and delicate metalwork catch and reflect the light, casting a dazzling display across the room. Each facet of the chandelier sparkles with its own brilliance, creating an atmosphere of opulence and charm. Whether adorned with classic crystal droplets that evoke a bygone era or designed with sleek, modern lines for contemporary spaces, a chandelier transforms any room into a scene of enchantment. It serves not only as a functional fixture but as a focal point, commanding attention and adding a touch of luxury to any space it illuminates.",
        general_image:"/Assets/Buska Lighting/Chendelier/img_1-removebg-preview.png ",
        product_count:10,
        item:[
            {
                item_code:1,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Chendelier/img_1_bg_removed.png",
                item_insetting_img:"/Assets/Buska Lighting/Chendelier/img_1_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:2,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Chendelier/img_2.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Chendelier/img_2-removebg-preview.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:3,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Chendelier/img_3.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Chendelier/img_3-removebg-preview.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:4,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Chendelier/img_4.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Chendelier/img_4-removebg-preview.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:5,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Chendelier/img_5.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Chendelier/img_5-removebg-preview.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:6,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Chendelier/img_6.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Chendelier/img_6-removebg-preview.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
        ],
       
    },
    {
        collection_name:"Pendent Light",
        Description:"A pendant light is a beacon of style and functionality, suspended like a jewel from the ceiling. Its design ranges from minimalist chic to intricate craftsmanship, each pendant light serving as a focal point in any room. Whether crafted from gleaming metal, natural materials like wood, or adorned with colorful glass shades, a pendant light not only illuminates but also elevates the ambiance. It casts a warm glow, creating depth and character while effortlessly blending with any interior style. Perfect for dining areas, kitchens, or even as a statement piece in a hallway, a pendant light adds a touch of modern sophistication and timeless allure to your living spaces.",
        general_image:"/Assets/Buska Lighting/Pendant Light/img_12_bg_removed.png ",
        product_count:10,
        item:[
            {
                item_code:101,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Pendant Light/img 12.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Pendant Light/img_12_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:102,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Pendant Light/img 2.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Pendant Light/img_2_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:103,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Pendant Light/img 5.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Pendant Light/img_5_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:104,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Pendant Light/img 9.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Pendant Light/img_9_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:105,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Pendant Light/img 11.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Pendant Light/img_11_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:106,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Pendant Light/img 13.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Pendant Light/img_13_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
        ],

    },
    {
        collection_name:"Floor Lamp",
        Description:"A floor light, or floor lamp, stands as a beacon of both practicality and design in any room. Rising elegantly from the floor, it offers versatile illumination and serves as a striking piece of decor. From sleek, minimalist designs that seamlessly blend into contemporary interiors to ornate, vintage-inspired styles that evoke a sense of nostalgia, a floor light complements any aesthetic. Whether adorned with a fabric drum shade for soft, ambient light or featuring adjustable arms for focused task lighting, a floor lamp enhances the ambiance while providing essential lighting for reading nooks, living areas, or bedrooms. It stands tall as a functional art piece, casting a warm glow that transforms any space into a cozy retreat.",
        general_image:"/Assets/Buska Lighting/Floor Lamp/img_6_bg_removed.png ",
        product_count:10,
        item:[
            {
                item_code:201,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Floor Lamp/img_6.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Floor Lamp/img_6_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:203,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Floor Lamp/img 1.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Floor Lamp/img_1_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:204,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Floor Lamp/img 2.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Floor Lamp/img_2_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:205,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Floor Lamp/img 3.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Floor Lamp/img_3_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
           
         
        ],

    },
    {
        collection_name:"Table Lamp",
        Description:"A table light, or table lamp, exudes both charm and practicality, sitting proudly atop desks, bedside tables, or sideboards. Its design ranges from classic elegance to contemporary flair, offering a beacon of light and style in any setting. Whether crafted from glossy ceramic, polished metal, or natural materials like wood, a table lamp adds a touch of personality to its surroundings. With shades ranging from delicate fabric to bold, patterned designs, a table lamp casts a soft, inviting glow that enhances ambiance and functionality. Perfect for reading corners, office desks, or as a decorative accent in living rooms, a table lamp not only illuminates but also elevates the aesthetic appeal of your interior spaces",
        general_image:"/Assets/Buska Lighting/Table Light/img_5_bg_removed.png ",
        product_count:10,
        item:[
            {
                item_code:301,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Table Light/img 5.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Table Light/img_5_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:302,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Table Light/img 3.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Table Light/img_3_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:303,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Table Light/img 6.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Table Light/img_6_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:304,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Table Light/img 7.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Table Light/img_7_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:305,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Table Light/img 4.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Table Light/img _4_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
        
        ],

    },
    {
        collection_name:"Wall Lamp",
        Description:"A wall light, or wall sconce, stands as a testament to both elegance and practicality, adorning walls with a touch of sophistication and illumination. Its design varies from sleek, modern fixtures that blend seamlessly into contemporary interiors to ornate, vintage-inspired pieces that add a sense of history and charm. Whether crafted from brushed metal, rustic iron, or adorned with delicate glass shades, a wall sconce offers ambient lighting and accentuates architectural features. Mounted beside mirrors, along hallways, or framing artwork, a wall light casts a warm, inviting glow that enhances the ambiance of any room. It serves as both a functional necessity and a stylish addition, enriching the visual appeal of your living spaces with its understated beauty.",
        general_image:"/Assets/Buska Lighting/Wall Lamp/img_1_bg_removed.png",
        product_count:10,
        item:[
            {
                item_code:401,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Wall Lamp/img 1.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Wall Lamp/img_1_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:402,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Wall Lamp/img 2.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Wall Lamp/img_2_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:403,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Wall Lamp/img 6.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Wall Lamp/img_6_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:404,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Wall Lamp/img 7.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Wall Lamp/img_7_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:405,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Wall Lamp/img 8.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Wall Lamp/img_8_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
            {
                item_code:406,
                item_description:"light light",
                item_general_img:"/Assets/Buska Lighting/Wall Lamp/img 9.jpg",
                item_insetting_img:"/Assets/Buska Lighting/Wall Lamp/img_9_bg_removed.png",
                item_price:"2000",
                material:[
                    {
                        type:"wood",
                        image:"  ",
                        price:1200,
                    },
                    {
                        type:"metal",
                        image:"  ",
                        price:1200,
                    }
                ]
                
            },
        ],

    },
 

]

export default collection
