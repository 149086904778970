import React, { useEffect,useState } from 'react'
import "../Css/catagory.css"
import collection from '../dummData/dummData';
import { useNavigate,useParams } from 'react-router-dom'
function Catagory() {
const navigate= useNavigate();
const {catagory}=useParams();
const [List,setList]=useState(null);
useEffect(()=>{
    const [product]=collection.filter((data)=>(data.collection_name==catagory));
    setList(product);
},[useParams()])
const handelNavigation=(code)=>{

    navigate(`/collections/${catagory}/${code}`)
}
return (
<div className='container-fluid'>
    <div className='row'>
        {
            List && List.item.map((data)=>{
                return(
                    <div key={data.item_code} className='col-sm-12 col-md-4 p-3 rounded' >
                    <div className='catagory_item_container rounded ' onClick={()=>handelNavigation(data.item_code)}>
                        <img src={data.item_insetting_img} alt='' className='img-fluid catagory_img_above rounded'/>
                        <img src={data.item_general_img} alt='' className='img-fluid catagory_img_below rounded'/>
                        <h6>Montfaucon Chandelier</h6>
                    </div>
                </div>    
                )
            })
        }
       
        


    </div>
</div>
) }
export default Catagory