import React from 'react'
import "../Css/footer.css";
import { ArrowRight, LocationOn, Mail, Phone, Pin } from '@mui/icons-material'
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import CopyrightIcon from '@mui/icons-material/Copyright';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


function Footer() {
  return (
    <>
    <div className='container'>
        <hr/>
        <div className="row">
            <div className='col-sm-12 col-lg-4 d-flex flex-column p-4'>
                <h5 className='footer_title'>Contact Us</h5>
                <div className=''>
                <img className='footer_logo' src='/Assets/BuskaLogo.png' alt='logo'/>
                 </div>
                    <div className='footer_company_intro'>
                        <p>
                        Buska Lighting is Enlightened by Design. We’re excited by design, too. We travel the world to bring you everything from authentic and iconic pieces, to the latest in contemporary interior décor, all sourced from global design brands, including exclusive pieces you won’t find anywhere else.
                        </p>
                    </div>
            
                
            
            </div>
            <div className='col-sm-12 col-lg-8  '>
               <div className='row'>
               <div className='col-sm-12 col-lg-4 useful_link_container '>
                             <h5 className='footer_title mb-3'>Use full links</h5>
                             <Link to="/about_us" className='useful_link'><h6><ArrowRight/> About Us</h6></Link>
                             <Link to="/contact" className='useful_link'> <h6> <ArrowRight/>Contact</h6></Link>
                        </div>
                        <div className= ' col-sm-12 col-lg-4 useful_link_container'>
                             <h5 className='footer_title mb-3'>Service</h5>
                             <h6> <ArrowRight/>About Us</h6>
                             <h6> <ArrowRight/>Contact</h6>
                        </div>
                        <div className=' col-sm-12 col-lg-4  useful_link_container'>
                             <h5 className='footer_title mb-3'>Contact Info</h5>
                             <div className=' footer_contact_container'>
                    <h6><LocationOn/>: Bole Brass Hayleyesus office compound next to Yod Abyssinia Resturant office No S-2-0</h6>
                 </div>
                 <div className=' d-flex flex-column my-2 justifiy-content-around footer_contact_container '>
                    <h6> <Phone/>: +251-939-928-384</h6>
                    <h6> +251-939-928-384(SMS only)</h6>
                    <h6><Mail/>: Formonic.architects@gmail.com</h6>
                    <div className='social_media_container'>
                        <div className='social_media_icon_container'>
                            <a target="_blank" href='https://t.me/buskalights' className='nav-link'><TelegramIcon/></a>
                        </div>
                        <div className='social_media_icon_container'>
                        <a target="_blank" href='https://www.instagram.com/buskalights?igsh=dTliY3dhbW12Nmhh' className='nav-link'><InstagramIcon/></a>
                            
                        </div>
                        <div className='social_media_icon_container'>
                        <FacebookIcon/>
                        </div>
                        <div className='social_media_icon_container'>
                            <WhatsAppIcon/>
                        </div>
                    </div>
                 </div>
                 
                        </div>
               </div>

                </div>
            </div>
      
        <hr/>
    </div>
    <div className='container-fluid  p-0 rounded'>
            <div className='col-sm-12 p-0'>
                <div className='site_ownership_container'>
                    <h5 className=''><CopyrightIcon/>Copyright Buska. All Rights Reserved</h5>
                    <h6>Designed by <span>Cube Addis Solutions</span></h6>
                    </div>
            </div>
        </div>
    </>
  )
}

export default Footer