import React from 'react'
import "../Css/Herosection.css"
function Hero_section() {
  return (
    <div>
           <div className='container carousel_container'>
      <div className='container-fluid '>
      <div id="demo" className="carousel slide h-100" data-bs-ride="carousel">

{/* <!-- Indicators/dots --> */}
{/* <div className="carousel-indicators">
  <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
  <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
  <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
</div> */}

{/* <!-- The slideshow/carousel --> */}
<div className="carousel-inner carousel_item ">
  <div className="carousel-item active  carousel_item">
  <div className=' '>
  <h2 className="display-4 fw-bold w-100 text-center container">1Welcome to <span>Anyar</span></h2>
          <p className="fw-bolder ">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
          
  </div>
   
  </div>
  <div className="carousel-item carousel_item">
  <div className=' '>
  <h2 className="display-4 fw-bold w-100 text-center">2Welcome to <span>Anyar</span></h2>
          <p className="fw-bolder">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
          
  </div>       
 
  </div>

</div>

{/* <!-- Left and right controls/icons --> */}
 <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
  <span className="carousel-control-prev-icon"></span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
  <span className="carousel-control-next-icon"></span>
</button> 
      </div>
      </div>
      </div>
    </div>
  )
}

export default Hero_section