import { ArrowRight } from '@mui/icons-material'
import React from 'react'
import "../Css/Item.css"

function Item() {
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ETB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
  return (
    <>
    <div className='container-fluid bg-light'>
        <div className='container'>
          <div className='p-3'>
          <h6 className=''> Home <ArrowRight/> Flex LED Pendant Light<ArrowRight/>code:#4536  </h6>
          </div>
        </div>
        </div>
        <div className='container mt-3'>
            <div className='row'>
                <div className='col-sm-12 col-lg-6 p-5'>
                <div id="demo" className="carousel slide" data-bs-ride="carousel">

  {/* <!-- Indicators/dots --> */}
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>

  {/* <!-- The slideshow/carousel --> */}
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src='/Assets/Buska Lighting/Chendelier/img_1.jpg' alt="Los Angeles" className="d-block w-100"/>
    </div>
    <div className="carousel-item">
      <img src='/Assets/Buska Lighting/Chendelier/img_3.jpg' alt="Chicago" className="d-block w-100"/>
    </div>
    <div className="carousel-item">
      <img src='/Assets/Buska Lighting/Chendelier/img_4.jpg' alt="New York" className="d-block w-100"/>
    </div>
  </div>

  {/* <!-- Left and right controls/icons --> */}
  <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span className="carousel-control-prev-icon"></span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span className="carousel-control-next-icon"></span>
  </button>
</div>              
                </div>
                <div className='col-sm-12 col-lg-6'>
                   <div className="item_detail_container">
                        <h3 className='item_title'> Nori Pendant Lamp</h3>
                        <h6 className='my-2'>Catagory : <span className='fw-bolder'>Pendant Light </span></h6>
                        <h6  className='my-2'>Availability : <span className='text-success fw-bold'>In Stock </span></h6>
                        <div className='d-flex my-2'>
                            <div>
                                <h6 className='text-secondary default_text_size'>Size :</h6>
                            </div>
                            <div className='item_size_container'>
                                <div>
                                    <h6>Dia 18cm x H 27cm / ∅ 7.1″ x H 10.6″ </h6>
                                </div>
                                <div>
                                    <h6>Dia 24cm x H 27cm / ∅ 9.5″ x H 10.6″</h6>
                                </div>
                                <div>
                                    <h6>Dia 39cm x H 27cm / ∅ 15.4″ x H 10.6″ </h6>
                                </div>
                            </div>
                        </div>
                        <div className='material_container'>
                            <h6>Materials :</h6>
                            <select className='form-control fw-bold w-75'>
                                <option value="Null"> </option>
                                <option value="Wood">Wood</option>
                                <option value="Metal">Metal</option>
                            </select>
                        </div>
                        <div className='price_container'>
                            <span className='bg-success btn text-white p-2'>{nf.format(2000)}</span>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

  
    </>
  )
}

export default Item