import React, {useState,useEffect} from 'react'
import Catagory from '../Components/Catagory'
import Navigation_bar from '../Components/Navigation_bar'
import collection from '../dummData/dummData';
import { useNavigate,useParams } from 'react-router-dom'
import "../Css/collectionspage.css"

function CollectionsPage() {
  const {catagory}=useParams();
const [List,setList]=useState(null);
useEffect(()=>{
    const [product]=collection.filter((data)=>(data.collection_name==catagory));
    setList(product);
},[useParams()])
  return (
    <>
 
   <div className='container'>
       <div className='row'>
           <div className='col-sm-12'>
               <div className='catagory_description'>
                   <h6> {List?.collection_name}</h6>
                   <p>
                     {
                       
                       List?.Description
                     }

                   </p>
                 </div>

           </div>
           <div className='col-sm-12'>
                
                    <Catagory/>
           



           </div>

       </div>

   </div>
    </>
  )
}

export default CollectionsPage