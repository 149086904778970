import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import "../Css/navigation_bar.css"
function Navigation_bar() {
  const navigate=useNavigate();
  const navigation=(catagory)=>
  {
    navigate(`/Collections/${catagory}`);

  }
  return (
    <>
    <div className='container-fluid bg-light' id="topbar">
      <div className='container '>
        <div className='row'>
          <div className='topbar'>
            <h6>If you have any questions, please call: 802-297-7666</h6>
          </div>
        </div>
      </div>
      </div>
    <div className='container p-lg-4'>
        <nav className="navbar navbar-expand-sm bg-white text-dark ">
          <div className="container-fluid ">
           <img src='/Assets/BuskaLogo.png' alt='' className='nav-logo mx-4'/>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse  " id="collapsibleNavbar">
              <ul className="navbar-nav w-100   d-flex justify-content-around">
                <li className="nav-item nav_item">
                 <Link to="/" className='nav-link'> Home</Link>
                </li>
                <div className="nav-item nav_item dropdown drop_down">
                <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">All Collections</Link>
                      <div className="dropdown-menu  dropdown_menu   shadow  border-0 ">
                        <div className='dropdown drop_down container rounded '>
                          <div className='row  d-none d-lg-flex '>
                            {/* only visibile on large screens */}
                            <div className=' dropdown_menu_container  p-1'>
                           <div className='dropdown-item  dropdown_item_container ' onClick={()=>navigation("Chandelier")}>
                              <img src='/Assets/Buska Lighting/Chendelier/img_1-removebg-preview.png' className='nav_dropdown_img' alt=''/>
                              <h5>Chandelier</h5>
                            </div>
                            
                            <div className='dropdown-item  dropdown_item_container ' onClick={()=>navigation("Pendent Light")}>
                              <img src='/Assets/pendent.jpg' className='nav_dropdown_img' alt=''/>
                              <h5>Pendent Light</h5>
                            </div>                            
                            <div className='dropdown-item  dropdown_item_container ' onClick={()=>navigation("Table Lamp")}>
                              <img src='/Assets/table lamp.jpg' className='nav_dropdown_img' alt=''/>
                              <h5>Table Light</h5>
                            </div>    
                            <div className='dropdown-item   dropdown_item_container'onClick={()=>navigation("Wall Lamp")}>
                              <img src='/Assets/wall light.jpg' className='nav_dropdown_img' alt=''/>
                              <h5>Wall Light</h5>
                            </div>  
                            <div className='dropdown-item   dropdown_item_container ' onClick={()=>navigation("Floor Lamp")}>
                              <img src='/Assets/floor_light.png' className='nav_dropdown_img' alt=''/>
                              <h5>Floor Light</h5>
                            </div>                         
                            </div>
                            {/*  */}
                           
                          </div>
                          <div className='     d-lg-none'>
                             
                             <li className=' dropdown-item ' onClick={()=>navigation("Chandelier")}>Chandelier  </li>
                             <li className=' dropdown-item ' onClick={()=>navigation("Pendent Light")}>Pendant Light  </li>
                             <li className=' dropdown-item'  onClick={()=>navigation("Table Lamp")}>Table Light  </li>
                             <li className=' dropdown-item ' onClick={()=>navigation("Floor Lamp")}>Floor Light </li>
                             <li className=' dropdown-item ' onClick={()=>navigation("Wall Lamp")}> Wall Light </li>
                         
                   </div>
                           </div>
                      </div>
                       {/* only visible on smalll screens */}
                </div>
                <li className="nav-item nav_item  dropdown">
                <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Keep In Touch</Link>
                <ul className="dropdown-menu shadow border-0">
                  <li><Link className="dropdown-item" href="#">Reviews</Link></li>
                  <li><Link className="dropdown-item" href="#">FAQs</Link></li>
                </ul>
            </li>
                <li className="nav-item nav_item">
                <Link className="nav-link" to="/about_us">About Us</Link>
                </li>
                <li className="nav-item nav_item">
                  <Link className="nav-link" href="#">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
      </nav>
    </div>
   
    </>
  )
}

export default Navigation_bar