import React from 'react'
import "../Css/Featured.css"
import { useNavigate } from 'react-router-dom'
function Featured() {
    const navigate=useNavigate();
    const navigation=(catagory)=>
    {
      navigate(`/Collections/${catagory}`)
  
    }
  return (
    <div className='container my-4'>
        <h2 className='w-100 text-center '> Featured Collections</h2>
        <h6 className='w-100 text-center text-secondary'> Discover Beat - Seiling</h6>
        <div className='row my-5'>
      
            <div  className='col-sm-12 col-lg-4   p-5 mx-auto' onClick={()=>navigation("Chandelier")}>
               <div className='featured_container shadow'>
               <div className="card  bg-light border-0 ">
                <img className="card-img-top" src='/Assets/Buska Lighting/Chendelier/img_3-removebg-preview.png' alt="Card image"/>
                    < div className="  card-img-overlay ">
                        <h4 className="card-title">Chandelier</h4>
                        
                    </div>
                    </div>
                </div> 
            </div>
            <div  className='col-sm-12 col-lg-4   p-5 mx-auto' onClick={()=>navigation("Pendent Light")}>
               <div className='featured_container shadow'>
               <div className="card  bg-light border-0 ">
                <img className="card-img-top" src='/Assets/Buska Lighting/Pendant Light/img_12_bg_removed.png' alt="Card image"/>
                    < div className="  card-img-overlay ">
                        <h4 className="card-title">Pendent Light</h4>
                        
                    </div>
                    </div>
                </div> 
            </div>
            <div  className='col-sm-12 col-lg-4   p-5 mx-auto'onClick={()=>navigation("Table Lamp")}>
               <div className='featured_container shadow'>
               <div className="card  bg-light border-0 ">
                <img className="card-img-top" src='/Assets/Buska Lighting/Table Light/img_5_bg_removed.png' alt="Card image"/>
                    < div className="  card-img-overlay ">
                        <h4 className="card-title">Table Light</h4>
                        
                    </div>
                    </div>
                </div> 
            </div>
            <div  className='col-sm-12 col-lg-4   p-5 mx-auto' onClick={()=>navigation("Wall Lamp")} >
               <div className='featured_container shadow'>
               <div className="card  bg-light border-0 ">
                <img className="card-img-top" src='/Assets/Buska Lighting/Wall Lamp/img_1_bg_removed.png' alt="Card image"/>
                    < div className="  card-img-overlay ">
                        <h4 className="card-title">Wall Light</h4>
                        
                    </div>
                    </div>
                </div> 
            </div>
            <div  className='col-sm-12 col-lg-4   p-5 mx-auto' onClick={()=>navigation("Floor Lamp")}>
               <div className='featured_container shadow'>
               <div className="card  bg-light border-0 ">
                <img className="card-img-top" src='/Assets/Buska Lighting/Floor Lamp/img_6_bg_removed.png' alt="Card image"/>
                    < div className="  card-img-overlay ">
                        <h4 className="card-title">Floor Light</h4>
                        
                    </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
  )
}

export default Featured