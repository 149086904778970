import * as ac_types from "./Actiontypes"
export const initialState=
{
   
    
   
 
};
export const memeber_reducer =(state=initialState,action)=>{
    switch(action.type){
        default:
            return state;
    }
}