import React from 'react'
import { Routes,Route } from 'react-router-dom';
import Footer from './Components/Footer';
import CollectionsPage from './Pages/CollectionsPage';
import Homepage from './Pages/Homepage';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Navigation_bar from './Components/Navigation_bar';
import Item from './Components/Item';
import Single_item from './Components/Single_item';
import About_us_page from './Pages/About_us_page';
function App() {
  return (
    <div className='container-fluid p-0'>
      <Navigation_bar/>
      <Routes>
        <Route path="/" exact element={<Homepage/>}/>
        <Route path="/Collections/:catagory" element={<CollectionsPage/>}/>
        <Route path="/Collections/:catagory/:code" element={<Item/>}/>
        <Route path="/About_us" element={<About_us_page/>}/>
      </Routes>
      <div className="goto__top">
         <div>
            <a href="#topbar" className="nav-link">
            <ArrowUpwardIcon/>
            </a>
        </div>
       </div>
    <Footer/>
    </div>
  );
}

export default App;
