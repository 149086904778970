import React from 'react'
import About_us from '../Components/About_us'
import Catalog from '../Components/Catalog'
import Featured from '../Components/Featured'
import Hero_section from '../Components/Hero_section'
import Navigation_bar from '../Components/Navigation_bar'


function Homepage() {
  return (
    <div>

       <Hero_section/>
       <Featured/>
       <Catalog/>
       <hr className='my-5'/>
        
       <About_us/>
    </div>
  )
}

export default Homepage