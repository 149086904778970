import React from 'react'
import "../Css/Catalog.css"
function Catalog() {
  return (
    <>
    <div className='container-fluid p-5 mb-6'>
        <h2 className='w-100 text-center '> Our Most Beautiful Designs</h2>
        <h6 className='w-100 text-center text-secondary mb-5'> Designs Category</h6>
        <div className='row'>
            <div className="col-sm-12 col-lg-6  ">
            <div className='Catalog_img_container '>
            <img className="img-fluid" shadow  src="/Assets/blog/blog-1.jpg" alt="Card image"/>
               <div>
               <h5>Out door Light</h5>
                <h6> 20 products </h6>
               </div>
                </div> 
            </div>
            <div className="col-sm-12 col-lg-6 ">
            <div className='Catalog_img_container '>
            <img  src="/Assets/blog/blog-2.jpg"  alt='' className='img-fluid'/>
               <div>
               <h5>Out door Light</h5>
                <h6> 20 products </h6>
               </div>
                </div> 
            
            </div>
        </div>
        </div>
        <hr className='mt-5'/>
        <div className='container-fluid p-1 bg-light'>
        <div className='row'>
        <div className='col-sm-12 col-lg-6'>
            <img className='img-fluid' src="/Assets/blog/component-1.jpg" alt=""/>
        </div>
        <div className='col-sm-12 col-lg-6'>
            <div className=' catalog_content_container mt-lg-5 '>
                <h5>Discover Our New Product</h5>
                <p>Design for Living, Since 2016, our goal has been to provide
                    better living through design. <br/> we carry an ever growing collection
                    of modern, well designed products by the world's top brands.</p>
            </div>
        </div>
       </div>
       <div className='row'>
        
        <div className='col-sm-12 col-lg-6'>
            <div className=' catalog_content_container mt-lg-5'>
                <h5>Natural Materials</h5>
                <p>Alabaster, bringing an understated elegance and timeless beauty to the forefront of craftsmanship, captivates both tactile and visual senses</p>
            </div>
        </div>
        <div className='col-sm-12 col-lg-6'>
            <img className='img-fluid w-100' src="/Assets/blog/component-2.jpg" alt=""/>
        </div>
       </div>
       

        </div>
    </>
  
  )
}

export default Catalog